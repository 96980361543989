import React from 'react'


const HeaderImageWithText = (props) => {
    return (
        <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image
          })`,
        }}
      >
        <h2
          className="has-text-weight-bold is-size-1"
          style={{
            boxShadow: '0.5rem 0 0 white, -0.5rem 0 0 white',
            backgroundColor: 'white',
            color: '#0067AC',
            padding: '1rem',
          }}
        >
          {props.title}
        </h2>
      </div>
    )
  }
  
  export default HeaderImageWithText